'use client';

import { useEffect, useState } from 'react';

import Link from 'next/link';
import { usePathname } from 'next/navigation';

import { cn } from '@/utils';

const tabList = [
  {
    label: '期刊',
    name: 'music',
    href: '/',
  },
  {
    label: '专栏',
    name: 'essay',
    href: '/essay/list',
  },
];

export default function Tab() {
  const pathName = usePathname();
  const [selected, setSelected] = useState('music');
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    const path = pathName.split('/')[1];
    const tab = path === 'essay' ? 'essay' : 'music';
    const index = tabList.findIndex((item) => item.name === tab);
    console.log({ index, a: 2 + 58 * index });
    setSelected(tab);
    setSelectedIndex(index);
  }, [pathName]);

  return (
    <div className="w-full">
      <div className="flex items-center gap-x-24px flex-wrap w-full">
        {tabList.map((item) => (
          <Link
            href={item.href}
            key={item.name}
            className={`flex flex-col justify-between items-center w-fit h-full text-17px leading-24px text-center cursor-pointer ${
              item.name === selected ? 'text-brand' : 'text-base hover:text-brand'
            }`}
          >
            <div>{item.label}</div>
          </Link>
        ))}
      </div>

      <div
        className="w-30px h-2px mt-6px bg-brand transition-all duration-300"
        style={{ marginLeft: `${2 + 58 * selectedIndex}px` }}
      />
    </div>
  );
}
